import React from 'react';

import About from './pages/AboutUs';
import Privacy from './pages/Privacy';
import PrivacyCalifornia from './pages/PrivacyCalifornia';
import StatusCheck from './pages/StatusCheck';
import PlayerInvite from './pages/PlayerInvite';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import Home from './pages/Home';
import NewHome from './pages/NewHome';
import AddTeam from './pages/team/AddTeam';
import CreateTeam from './pages/team/CreateTeam';
import CreateNewPass from './pages/user/CreateNewPass';
import Login from './r-views/pages/login/Login';
import { Subscription } from './pages/stripe/Subscription';
import Why from './pages/Why6Tool';
import QuestionWrapper from './pages/component/QuestionWrapper';
import GroupsListPage from './r-views/pages/groups/list/groupsList';
import TeamView from './r-views/pages/teams/TeamView';
import OrganizationView from './r-views/pages/organizations/OrganizationView';
import StripeTest from './pages/stripe/StripeTest';
import _ from 'lodash';
import PlayerUpdate from './r-views/pages/playerUpdate/PlayerUpdate';
import CoachUpdate from './r-views/pages/coachUpdate/CoachUpdate';
import PlayerDetails from './r-views/pages/playerDetails/PlayerDetails';
import Players from './r-views/pages/players/Players';
import AddGroup from './r-views/pages/groups/add/AddGroup';
import EditGroup from './r-views/pages/groups/edit/EditGroup';
import GroupDetails from './r-views/pages/groups/detail/groupDetail';
import QuizzesList from './r-views/pages/quizzesList/QuizzesList';
import ExamDetailsPage from './r-views/pages/examDetails/ExamDetails';
import QuestionList from './r-views/pages/questionsList/QuestionList';
import CreateQuestion from './r-views/pages/questionsList/CreateQuestion';
import QuestionDetailsPage from './r-views/pages/examDetails/ExamDetails';
import Coaches from './r-views/pages/coaches/Coaches';
import TeamEdit from './r-views/pages/teams/TeamEdit';
import CoachDetails from './r-views/pages/coachDetails/CoachDetails';
import ProfileSecurity from './r-views/pages/profile/ProfileSecurity';
import ProfileTeams from './r-views/pages/profile/ProfileTeams';
import ProfileDetails from './r-views/pages/profile/ProfileDetails';
import QuizzesInvites from './r-views/pages/quizzesInvites/QuizzesInvites';
import QuizzesTaken from './r-views/pages/quizzesTaken/QuizzesTaken';
import ProfileSubscription from './r-views/pages/profile/ProfileSubscription';
import { handleError } from './helpers/errors';
import TeamCreate from './r-views/pages/teams/TeamCreate';
import MediaGalleryPage from './r-views/pages/MediaGallery/MediaGalleryPage';
import DownloadApp from './pages/DownloadApp';
import TeamPlayerRequests from './r-views/pages/teams/TeamPlayerRequests';
import TeamRequestAccess from './r-views/pages/teams/TeamRequestAccess';
import SignupCreateCoach from './pages/user/SignupCreateCoach';
import SignupRedirectPlayerToDownload from './pages/user/SignupRedirectPlayerToDownload';
import TeamCoachRequests from './r-views/pages/teams/TeamCoachRequests';
import PublicOnlyLayout from './pages/component/layout/PublicOnlyLayout';
import OrphanLayout from './pages/component/layout/OrphanLayout';
import InactiveSubscriptionLayout from './pages/component/layout/InactiveSubscriptionLayout';
import Pricing from './pages/Pricing';
import Team from './pages/Team';
import FAQ from './pages/FAQ';
import FunnelLayout from './pages/component/layout/FunnelLayout';
import SignupSuccess from './pages/stripe/SignupSuccess';
import QuizzesScheduledPage from './r-views/pages/quizzesList/QuizzesScheduled';
import Events from './r-views/pages/events/Events';
import EventUpdate from './r-views/pages/events/eventUpdate/EventUpdate';
import EventLeaderboard from './r-views/pages/events/eventLeaderboard/EventLeaderboard';
import EmbedEventLeaderboard from './embed/EmbedEventLeaderboard';
import EventParticipants from './r-views/pages/events/eventParticipants/EventParticipants';
import EventTeams from './r-views/pages/events/eventTeams/EventTeams';
import EventQuizzes from './r-views/pages/events/eventQuizzes/EventQuizzes';
import SignupCreateFreeUser from './pages/user/SignupCreateFreeUser';
import PublicLayout from './pages/component/layout/PublicLayout';
import SignupChooseTier from './pages/user/SignupChooseTier';
import SuperAdminCreateFiendly from './pages/superAdmin/SuperAdminCreateFriendly';
import SuperAdminUploadUsers from './pages/superAdmin/SuperAdminUploadUsers';
import CreateAccountForm from './pages/user/CreateAccountForm';
import CreateAccountFormSuccess from './pages/user/CreateAccountFormSuccess';
import SignupSetupAccountForm from './pages/user/SignupSetupAccountForm';
import FunnelUserLayout from './pages/component/layout/FunnelUserLayout';
import SuperAdminQuestionCounts from './pages/superAdmin/SuperAdminQuestionCounts';
import SuperAdminImpersonate from './pages/superAdmin/SuperAdminImpersonate';
import EmbedQuizResults from './embed/EmbedQuizResults';
import CreatePassword from './r-views/pages/password/CreatePassword';
// import ReportsGalleryPage from './r-views/pages/MediaGallery/ReportsGalleryPage';

export const paramEncode = (param) => {
  return _.kebabCase(param);
};

export const ROUTE_ELEMENTS = {
  HOME: 'app',
  QUESTIONS: 'questions',
  QUIZZES: 'quizzes',
  PLAYERS: 'players',
  COACHES: 'coaches',
  GROUPS: 'groups',
  TEAMS: 'teams',
  EVENTS: 'events',
  EDIT: 'edit',
  CREATE: 'create',
  PROFILE: 'profile',
  SUBSCRIPTION: 'subscription',
  SECURITY: 'security',
  ACCOUNT: 'account',
  DETAILS: 'details',
  LEADERBOARD: 'leaderboard',
  PARAM_NAME: ':name',
  PARAM_ID: ':id',
  ADMIN: 'admin',
};

export const AMBASSADOR_LINKS = [
  'clark',
  'coachescorner',
  'cosgriff',
  'devit',
  'devitt',
  'doyle',
  'duffy',
  'goodro',
  'murphy',
  'penrose',
  'prospectwire',
  'pw',
  'schaefer',
  'schaeffer',
  'tiner',
  'tyner',
];

export const rootPathForPath = ({ path }) => {
  try {
    if (_.isString(path) && path.startsWith(`/${ROUTE_ELEMENTS.HOME}`)) {
      let cleanPath = path.replace(`/${ROUTE_ELEMENTS.HOME}/`, '');
      let cleanPathArray = cleanPath.split('/');
      if (_.isArray(cleanPathArray) && cleanPathArray.length > 1) {
        return `/${ROUTE_ELEMENTS.HOME}/${cleanPathArray[0]}`;
      }
    }
    return path || '';
  } catch (error) {
    handleError({ error, internal: 'AppTabs error determining root path' });
    return path || '';
  }
};
export const tabForPathArrayAndAction = ({ pathObject, pathAction }) => {
  switch (pathObject) {
    case ROUTE_ELEMENTS.COACHES:
      switch (pathAction) {
        case ROUTE_ELEMENTS.EDIT:
          return TABS.OWNER_COACHES_EDIT;
        case ROUTE_ELEMENTS.CREATE:
          return TABS.OWNER_COACHES_CREATE;
        case ROUTE_ELEMENTS.DETAILS:
          return TABS.OWNER_COACHES_DETAILS;
        default:
          return TABS.OWNER_COACHES;
      }
    case ROUTE_ELEMENTS.PLAYERS:
      switch (pathAction) {
        case ROUTE_ELEMENTS.EDIT:
          return TABS.COACH_PLAYERS_EDIT;
        case ROUTE_ELEMENTS.CREATE:
          return TABS.COACH_PLAYERS_CREATE;
        case ROUTE_ELEMENTS.DETAILS:
          return TABS.COACH_PLAYERS_DETAILS;
        default:
          return TABS.COACH_PLAYERS;
      }
    case ROUTE_ELEMENTS.GROUPS:
      switch (pathAction) {
        case ROUTE_ELEMENTS.EDIT:
          return TABS.COACH_GROUPS_EDIT;
        case ROUTE_ELEMENTS.CREATE:
          return TABS.COACH_GROUPS_CREATE;
        case ROUTE_ELEMENTS.DETAILS:
          return TABS.COACH_GROUPS_DETAILS;
        default:
          return TABS.COACH_GROUPS;
      }
    case ROUTE_ELEMENTS.TEAMS:
      switch (pathAction) {
        // case ROUTE_ELEMENTS.EDIT:
        //   return TABS.COACH_TEAMS;
        case ROUTE_ELEMENTS.CREATE:
          return TABS.COACH_TEAMS_CREATE;
        // case ROUTE_ELEMENTS.DETAILS:
        //   return TABS.COACH_TEAMS;
        default:
          return TABS.COACH_TEAMS;
      }
    case ROUTE_ELEMENTS.QUESTIONS:
      switch (pathAction) {
        case ROUTE_ELEMENTS.EDIT:
          return TABS.COACH_QUESTIONS_EDIT;
        case ROUTE_ELEMENTS.CREATE:
          return TABS.COACH_QUESTIONS_CREATE;
        case ROUTE_ELEMENTS.DETAILS:
          return TABS.COACH_QUESTIONS_DETAILS;
        default:
          return TABS.COACH_QUESTIONS;
      }
    case ROUTE_ELEMENTS.QUIZZES:
      switch (pathAction) {
        // case ROUTE_ELEMENTS.EDIT:
        //   return TABS.COACH_QUIZZES_CREATE
        case ROUTE_ELEMENTS.CREATE:
          return TABS.COACH_QUIZZES_CREATE;
        case ROUTE_ELEMENTS.DETAILS:
          return TABS.COACH_QUIZZES_DETAILS;
        default:
          return TABS.COACH_QUIZZES;
      }
    case ROUTE_ELEMENTS.EVENTS:
      switch (pathAction) {
        case ROUTE_ELEMENTS.LEADERBOARD:
          return TABS.EVENT_LEADERBOARD;
        case ROUTE_ELEMENTS.DETAILS:
          return TABS.EVENT_EVENT_DETAILS;
        default:
          return TABS.EVENT_EVENTS;
      }
    default:
      return TABS.COACH_APP_HOME;
  }
};

const makeRoute = (routeArray) => {
  return `/${routeArray.join('/')}`;
};

const appQuestionsPath = makeRoute([
  ROUTE_ELEMENTS.HOME,
  ROUTE_ELEMENTS.QUESTIONS,
]);
const appQuizzesPath = makeRoute([ROUTE_ELEMENTS.HOME, ROUTE_ELEMENTS.QUIZZES]);
const appEventsEventsPath = makeRoute([
  ROUTE_ELEMENTS.HOME,
  ROUTE_ELEMENTS.EVENTS,
]);
const appProfileAccountPath = makeRoute([
  ROUTE_ELEMENTS.HOME,
  ROUTE_ELEMENTS.PROFILE,
  ROUTE_ELEMENTS.ACCOUNT,
]);
export const DYNAMIC_LINK_DOWNLOAD = 'https://sixtool.page.link/download';
export const TABS = {
  HOME: {
    path: '/',
    component: Home,
  },
  BLANK_NEW_HOME: {
    path: '/new-home',
    component: NewHome,
  },
  LOGIN: {
    path: '/login',
    component: Login,
    layout: PublicOnlyLayout,
  },
  SIGNUP: {
    path: '/signup',
    redirect: '/signup/create-account',
  },
  LINKS: {
    path: '/links',
    redirect: 'https://linktr.ee/6tool',
  },
  SUPER_ADMIN_CREATE_FRIENDLY: {
    path: '/admin/create-friendly',
    component: SuperAdminCreateFiendly,
  },
  SUPER_ADMIN_QUESTION_COUNTS: {
    path: '/admin/question-counts',
    component: SuperAdminQuestionCounts,
  },
  SUPER_ADMIN_UPLOAD_USERS: {
    path: '/admin/import-users',
    component: SuperAdminUploadUsers,
  },
  SUPER_ADMIN_IMPERSONATE: {
    path: '/admin/impersonate',
    component: SuperAdminImpersonate,
  },
  SIGNUP_CREATE_ACCOUNT: {
    path: '/signup/create-account',
    component: SignupCreateFreeUser,
    layout: FunnelUserLayout,
  },
  SIGNUP_CHOOSE_TIER: {
    path: '/signup/choose-tier',
    component: SignupChooseTier,
    layout: FunnelUserLayout,
  },
  SIGNUP_SETUP_ACCOUNT_FORM: {
    path: '/signup/setup-account-form',
    component: SignupSetupAccountForm,
    layout: FunnelUserLayout,
  },
  SIGNUP_CREATE_COACH: {
    path: '/signup/create-coach',
    component: SignupCreateCoach,
    layout: PublicLayout,
  },
  SIGNUP_PLAYER_REDIRECT: {
    path: '/signup/player-redirect',
    component: SignupRedirectPlayerToDownload,
    layout: PublicOnlyLayout,
  },
  CREATE_PASSWORD: {
    path: '/create-password',
    component: CreatePassword,
    layout: FunnelUserLayout,
  },
  CREATE_ACCOUNT_FORM: {
    path: '/create-account-form',
    component: CreateAccountForm,
    layout: FunnelLayout,
  },
  CREATE_ACCOUNT_FORM_SUCCESS: {
    path: '/create-account-form-success',
    component: CreateAccountFormSuccess,
    layout: FunnelLayout,
  },
  ABOUT: {
    path: '/aboutus',
    component: About,
  },
  TEAM: {
    path: '/team',
    component: Team,
  },
  FAQ: {
    path: '/faq',
    component: FAQ,
  },
  PRIVACY: {
    path: '/privacy',
    component: Privacy,
  },
  PRIVACY_CALIFORNIA: {
    path: '/privacy-ccpa',
    component: PrivacyCalifornia,
  },
  TERMS: {
    path: '/terms',
    component: Terms,
  },
  PRICING: {
    path: '/pricing',
    component: Pricing,
  },
  WHY: {
    path: '/why',
    component: Why,
  },
  CONTACT: {
    path: '/contact',
    component: Contact,
  },
  DOWNLOAD: {
    path: '/download',
    redirect: DYNAMIC_LINK_DOWNLOAD,
  },
  APP_DOWNLOAD: {
    path: '/download-app',
    component: DownloadApp,
  },
  SIGNUP_SUCCESS: {
    path: '/signup-success',
    component: SignupSuccess,
  },
  SUBSCRIPTION: {
    path: '/subscription',
    component: Subscription,
  },
  COACH_APP_HOME: {
    path: '/app',
    redirect: appQuizzesPath,
  },
  EVENTS_APP_HOME: {
    path: '/app/events',
    redirect: appEventsEventsPath,
  },
  EMBED_EVENTS_LEADERBOARD: {
    path: '/embed/event_leaderboard',
    component: EmbedEventLeaderboard,
  },
  EMBED_QUIZ_RESULTS: {
    path: '/embed/quiz_results',
    component: EmbedQuizResults,
  },
  COACH_TEAMS_CREATE: {
    path: '/app/teams/create',
    component: TeamCreate,
  },
  COACH_TEAM: {
    path: '/app/teams/:name/:id',
    pathVar: ({ name, id }) => `/app/teams/${paramEncode(name)}/${id}`,
    component: TeamView,
  },
  COACH_TEAMS_PLAYERS_REQUESTS: {
    path: '/app/players/team-requests',
    component: TeamPlayerRequests,
  },
  COACH_TEAMS_COACH_REQUESTS: {
    path: '/app/coaches/team-requests',
    component: TeamCoachRequests,
  },
  COACH_TEAMS: {
    path: '/app/teams',
    component: TeamView,
  },
  OWNER_ORGANIZATION: {
    path: '/app/organization',
    component: OrganizationView,
  },
  OWNER_ORGANIZATION_EDIT: {
    path: '/app/organization/edit',
    component: OrganizationView,
  },
  OWNER_TEAM_EDIT: {
    path: '/app/teams/edit/:name/:id',
    pathVar: ({ name, id }) => `/app/teams/edit/${paramEncode(name)}/${id}`,
    component: TeamEdit,
  },
  COACH_QUIZZES: {
    path: appQuizzesPath,
    component: QuizzesList,
  },
  COACH_QUIZZES_CREATE: {
    path: '/app/quizzes/create',
    redirect: appQuestionsPath,
  },
  COACH_QUIZZES_DETAILS: {
    path: '/app/quizzes/details/:name/:id',
    pathVar: ({ name, id }) =>
      `/app/quizzes/details/${paramEncode(name)}/${id}`,
    component: ExamDetailsPage,
  },
  COACH_QUIZ_INVITES: {
    path: '/app/quizzes/invites',
    component: QuizzesInvites,
  },
  COACH_QUIZ_SCHEDULED: {
    path: '/app/quizzes/scheduled',
    component: QuizzesScheduledPage,
  },
  COACH_QUIZ_TAKENS: {
    path: '/app/quizzes/taken',
    component: QuizzesTaken,
  },
  COACH_MEDIA: {
    path: '/app/media',
    component: MediaGalleryPage,
  },
  // COACH_REPORTS: {
  //   path: '/app/reports',
  //   component: ReportsGalleryPage,
  // },
  COACH_GROUPS: {
    path: '/app/groups',
    component: GroupsListPage,
  },
  COACH_GROUPS_CREATE: {
    path: '/app/groups/create',
    component: AddGroup,
  },
  COACH_GROUPS_EDIT: {
    path: '/app/groups/edit/:name/:id',
    pathVar: ({ name, id }) => `/app/groups/edit/${paramEncode(name)}/${id}`,
    component: EditGroup,
  },
  COACH_GROUPS_DETAILS: {
    path: '/app/groups/details/:name/:id',
    pathVar: ({ name, id }) => `/app/groups/details/${paramEncode(name)}/${id}`,
    component: GroupDetails,
  },
  COACH_QUESTIONS: {
    path: appQuestionsPath,
    component: QuestionList,
  },
  COACH_QUESTIONS_CREATE: {
    path: '/app/questions/create',
    component: CreateQuestion,
  },
  COACH_QUESTIONS_EDIT: {
    path: '/app/questions/edit/:id',
    pathVar: ({ id }) => `/app/questions/edit/${id}`,
    component: CreateQuestion,
  },
  COACH_QUESTIONS_DETAILS: {
    path: '/app/questions/details/:id',
    pathVar: ({ id }) => `/app/questions/details/${id}`,
    component: QuestionDetailsPage,
  },
  COACH_PLAYERS: {
    path: '/app/players',
    component: Players,
  },
  COACH_PLAYERS_CREATE: {
    path: '/app/players/create',
    component: PlayerUpdate,
  },
  COACH_PLAYERS_EDIT: {
    path: '/app/players/edit/:name/:id',
    pathVar: ({ name, id }) => `/app/players/edit/${paramEncode(name)}/${id}`,
    component: PlayerUpdate,
  },
  COACH_PLAYERS_DETAILS: {
    path: '/app/players/details/:name/:id',
    pathVar: ({ name, id }) =>
      `/app/players/details/${paramEncode(name)}/${id}`,
    component: PlayerDetails,
  },
  EVENT_EVENTS: {
    path: '/app/events/all',
    component: Events,
  },
  EVENT_PARTICIPANTS: {
    path: '/app/events/participants',
    component: EventParticipants,
  },
  EVENT_EVENT_DETAILS: {
    path: '/app/events/details/:name/:id/participants',
    pathVar: ({ name, id }) =>
      `/app/events/details/${paramEncode(name)}/${id}/participants`,
    component: EventParticipants,
  },
  EVENT_EVENT_PARTICIPANTS: {
    path: '/app/events/details/:name/:id',
    pathVar: ({ name, id }) => `/app/events/details/${paramEncode(name)}/${id}`,
    component: EventParticipants,
  },
  EVENT_EVENT_TEAMS: {
    path: '/app/events/details/:name/:id/teams',
    pathVar: ({ name, id }) =>
      `/app/events/details/${paramEncode(name)}/${id}/teams`,
    component: EventTeams,
  },
  EVENT_TEAMS: {
    path: '/app/events/teams',
    component: EventTeams,
  },
  EVENT_LEADERBOARD: {
    path: '/app/events/leaderboard/:name/:id',
    pathVar: ({ name, id }) =>
      `/app/events/leaderboard/${paramEncode(name)}/${id}`,
    component: EventLeaderboard,
  },
  EVENT_QUIZZES: {
    path: '/app/events/quizzes',
    component: EventQuizzes,
  },
  EVENT_EVENTS_EDIT: {
    path: '/app/events/edit/:name/:id',
    pathVar: ({ name, id }) => `/app/events/edit/${paramEncode(name)}/${id}`,
    component: EventUpdate,
  },
  EVENT_EVENT_CREATE: {
    path: '/app/events/create',
    component: EventUpdate,
  },
  OWNER_COACHES: {
    path: '/app/coaches',
    component: Coaches,
  },
  OWNER_COACHES_CREATE: {
    path: '/app/coaches/create',
    component: CoachUpdate,
  },
  OWNER_COACHES_EDIT: {
    path: '/app/coaches/edit/:name/:id',
    pathVar: ({ name, id }) => `/app/coaches/edit/${paramEncode(name)}/${id}`,
    component: CoachUpdate,
  },
  OWNER_COACHES_DETAILS: {
    path: '/app/coaches/details/:name/:id',
    pathVar: ({ name, id }) =>
      `/app/coaches/details/${paramEncode(name)}/${id}`,
    component: CoachDetails,
  },
  ORPHAN_REQUEST_TEAMS: {
    path: '/request-team-access',
    layout: OrphanLayout,
    component: TeamRequestAccess,
  },
  // USER_PROFILE: {
  //   path: '/app/profile',
  //   component: ProfilePage,
  // },
  USER_PROFILE_SECURITY: {
    path: '/app/profile/security',
    component: ProfileSecurity,
    layout: InactiveSubscriptionLayout,
  },
  OWNER_PROFILE_SUBSCRIPTION: {
    path: '/app/profile/subscription',
    component: ProfileSubscription,
    layout: InactiveSubscriptionLayout,
  },
  USER_PROFILE_DETAILS: {
    path: '/app/profile/details',
    component: ProfileDetails,
    layout: InactiveSubscriptionLayout,
  },
  // USER_PROFILE: {
  //   path: '/app/profile',
  //   redirect: appProfileAccountPath,
  // },
  OWNER_ADD_TEAM: {
    path: '/addteam',
    component: AddTeam,
  },
  OWNER_CREATE_TEAM: {
    path: '/createteam',
    component: CreateTeam,
  },
  OWNER_CREATE_TEAM_ID: {
    path: '/createteam/:id',
    component: CreateTeam,
  },
  CREATE_PASS: {
    path: '/createpass',
    component: CreateNewPass,
  },
  STATUS_CHECK: {
    path: '/status-check',
    component: StatusCheck,
  },
  PLAYER_INVITE: {
    path: '/player-invite',
    component: PlayerInvite,
  },
  STRIPE_TEST: {
    path: '/stripe-test',
    component: StripeTest,
  },
};
